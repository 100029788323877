import { createApi } from '@reduxjs/toolkit/query/react';
import { isQueryFnErrorResponse } from '@/store/queries';
import { publishMyPhoenixErrorEvent } from '@/myphoenix/utils/event-functions';
import {
  PERSISTENCE_DEFAULT_REFETCH_ON_ARG_OR_MOUNT_DURATION,
  PERSISTENCE_DEFAULT_REFETCH_UNUSED_DATA_DURATION,
} from '@/store/helpers/constants';
import { REHYDRATE } from 'redux-persist';

type FetchError = { statusText: string, status: number };
const isFetchErrorResponse = (response: unknown):
response is FetchError => (response as FetchError) !== undefined;

export const segmentApi = createApi({
  reducerPath: 'segment',
  baseQuery: undefined,
  refetchOnMountOrArgChange: PERSISTENCE_DEFAULT_REFETCH_ON_ARG_OR_MOUNT_DURATION,
  keepUnusedDataFor: PERSISTENCE_DEFAULT_REFETCH_UNUSED_DATA_DURATION,
  extractRehydrationInfo(action, { reducerPath }) {
    return (action?.type === REHYDRATE)
      ? action?.payload?.[reducerPath] : undefined;
  },
  endpoints: (build) => ({
    getAudiences: build.query({
      // personId is not used
      async queryFn({ personId }: { personId: string }) {
        try {
          /*
            personId doesn't really do anything for this fetch call
            It's added to keep this query unique so that it doesn't persist between sessions
           */
          const response = await fetch(`/api/services/get-audiences?personId=${personId}`, { method: 'GET' });
          const jsonResponse = await response.json();
          if (response.ok) {
            return { data: jsonResponse };
          }
          publishMyPhoenixErrorEvent(`Services-GetAudiences: ${response.status}: ${jsonResponse?.error}`);
          return { error: { message: jsonResponse?.error, statusCode: response.status } };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            publishMyPhoenixErrorEvent(`Services-GetAudiences: ${status}: ${statusText}`);
            return { error: { message: statusText, statusCode: status } };
          }
          if (isFetchErrorResponse(error)) {
            publishMyPhoenixErrorEvent(`Services-GetAudiences: ${error.status}: ${error.statusText}`);
            return { error: { message: error.statusText, statusCode: error.status } };
          }
          throw error;
        }
      },
    }),
  }),
});

export const {
  useGetAudiencesQuery,
} = segmentApi;
