import PropTypes from 'prop-types';
import Icon from '@/components/atoms/icon';
import Grid from '@mui/material/Grid';
import getCurrentAndFutureCourses from '@/helpers/filter-courses';
import { getEnrolledCourses } from '@/myphoenix/utils/course-functions';
import { useTheme } from '@mui/material/styles';
import { getClassUrl } from '@/myphoenix/utils/classroom-functions';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { useGetProgramsQuery } from '@/store/queries/programs';
import ArrowLink from '@/components/atoms/arrow-link';
import { toTitleCase } from '@/helpers/string-utils';
import {
  CourseListsContainer,
  CourseTitleContainer,
  CourseGridContainer,
  CourseCodeContainerCondensed,
  CourseCodeContainer,
  DirectAssessmentGridContainer,
} from './CourseSection.styles';

const componentName = 'CourseSection';

function DirectAssessmentCourseSection({ courses }) {
  return (
    <DirectAssessmentGridContainer
      container
      direction="row"
      wrap="nowrap"
    >
      <Grid sx={{ overflow: 'hidden' }} pb={1.5} px={0.6}>
        {courses.map((course) => (
          <div key={course.templateCode}>
            <Grid item xs>
              <CourseTitleContainer fontWeight={700} component="h3" variant="body4">
                {course.title}
              </CourseTitleContainer>
            </Grid>
            <Grid item xs={12} pb={2}>
              <CourseCodeContainerCondensed component="p" variant="body4">
                {course.templateCode}
              </CourseCodeContainerCondensed>
            </Grid>
          </div>
        ))}
        <Grid item xs={12}>
          <ArrowLink
            link={getClassUrl(true)}
            clickEvent={{ componentName, properties: [{ cbeDaProgram: true }] }}
          >
            <span>Go to class</span>
          </ArrowLink>
        </Grid>
      </Grid>
    </DirectAssessmentGridContainer>
  );
}
function CourseSection({
  courseLinks,
}) {
  const theme = useTheme();
  const currentCourses = getEnrolledCourses(courseLinks);
  const { filteredCourses, heading } = getCurrentAndFutureCourses(currentCourses);
  const condensed = filteredCourses?.length > 3;

  const { data: { primaryProgram } = {} } = useGetProgramsQuery(
    { personId: getPersonIdFromSessionStorage() },
  );
  const cbeDaProgram = primaryProgram?.cbeDaProgram === 'TRUE';
  const courseLinksSection = filteredCourses?.map((course) => (
    (condensed)
      ? (
        <CourseGridContainer
          container
          key={course.templateCode}
          direction="row"
          wrap="nowrap"
          condensed={condensed}
        >
          <Grid item>
            <Icon
              id="icon-note-book"
              icon="icon-note-book"
              fill={theme.palette.primary.main}
            />
            <CourseCodeContainer
              href={getClassUrl(primaryProgram?.cbeDaProgram === 'TRUE', course.id)}
              rel="noopener noreferrer"
              target="_blank"
            >
              {course.templateCode}
            </CourseCodeContainer>
          </Grid>
          <Grid item xs sx={{ overflow: 'hidden' }}>
            <Grid container>
              <CourseTitleContainer condensed={condensed} fontWeight={500} variant="body4">
                {toTitleCase(course.title)}
              </CourseTitleContainer>
            </Grid>
          </Grid>
        </CourseGridContainer>
      )
      : (
        <CourseGridContainer
          container
          key={course.templateCode}
          direction="row"
          wrap="nowrap"
          justifyContent="flext-start"
        >
          <Grid item>
            <Icon
              id="icon-note-book"
              icon="icon-note-book"
              fill={theme.palette.primary.main}
            />
          </Grid>
          <Grid item xs sx={{ overflow: 'hidden' }}>
            <Grid container direction="column" sx={{ marginLeft: theme.spacing(0.5) }}>
              <CourseTitleContainer fontWeight={500} component="h3" variant="body4">
                {toTitleCase(course.title)}
              </CourseTitleContainer>
              <Grid item xs>
                <CourseCodeContainerCondensed variant="body4">
                  {course.templateCode}
                </CourseCodeContainerCondensed>
              </Grid>
              <Grid item xs pb={1.5}>
                <ArrowLink
                  link={getClassUrl(cbeDaProgram, course.id)}
                  ariaLabel={`go to class ${course.templateCode}`}
                  clickEvent={{
                    componentName,
                    properties: [{ cbeDaProgram }, { templateCode: course.templateCode }],
                  }}
                >
                  <span>Go to class</span>
                </ArrowLink>
              </Grid>
            </Grid>
          </Grid>
        </CourseGridContainer>
      )
  ));

  return (
    <div>
      {filteredCourses?.length > 0
        ? (
          <CourseListsContainer>
            <h2 style={{ marginBottom: '15px' }}>{heading}</h2>
            {cbeDaProgram
              ? <DirectAssessmentCourseSection courses={filteredCourses} />
              : courseLinksSection}
          </CourseListsContainer>
        ) : ''}
    </div>
  );
}

CourseSection.propTypes = {
  courseLinks: PropTypes.arrayOf(
    PropTypes.shape({
    }),
  ),
};

CourseSection.defaultProps = {
  courseLinks: [],
};

export default CourseSection;
