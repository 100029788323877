const configs = {
  uop: {
    baseUrl: process.env.NEXT_PUBLIC_UOP_BASE_URL,
  },
  myphx: {
    baseUrl: process.env.NEXT_PUBLIC_BASE_URL,
  },
  aem: {
    baseUrl: process.env.NEXT_PUBLIC_AEM_BASE_URL,
  },
  blackboard: {
    baseUrl: process.env.NEXT_PUBLIC_BLACKBOARD_BASE,
    routerId: process.env.NEXT_PUBLIC_BLACKBOARD_ROUTER_ID,
  },
  brightspace: {
    baseUrl: process.env.NEXT_PUBLIC_BRIGHTSPACE_BASE,
  },
  careers: {
    baseUrl: process.env.NEXT_PUBLIC_CAREERS_BASE,
  },
  myphxPublic: {
    baseUrl: `${process.env.NEXT_PUBLIC_MYPHX_BASE_URL}/public`,
    goToClassInfo: process.env.NEXT_PUBLIC_GO_TO_CLASS_INFO,
  },
  jobExplorer: {
    baseUrl: process.env.NEXT_PUBLIC_JOB_EXPLORER_BASE,
  },
  ecampus: {
    baseUrl: process.env.NEXT_PUBLIC_ECAMPUS_BASE,
    homepagePath: process.env.NEXT_PUBLIC_ECAMPUS_HOMEPAGE,
    logoutEnabled: process.env.NEXT_PUBLIC_ECAMPUS_LOGOUT_ENABLED,
    logoutPath: process.env.NEXT_PUBLIC_ECAMPUS_LOGOUT_URI,
    loginPath: process.env.NEXT_PUBLIC_ECAMPUS_LOGIN_URI,
    leaveOfAbsencePath: process.env.NEXT_PUBLIC_ECAMPUS_LEAVE_OF_ABSENCE,
    officialWithdrawalPath: process.env.NEXT_PUBLIC_ECAMPUS_OFFICIAL_WITHDRAWAL,
  },
  multimedia: {
    baseUrl: process.env.NEXT_PUBLIC_MULTIMEDIA_BASE,
    logoutEnabled: process.env.NEXT_PUBLIC_MULTIMEDIA_LOGOUT_ENABLED,
    logoutPath: process.env.NEXT_PUBLIC_MULTIMEDIA_LOGOUT_URI,
  },
  salesforce: {
    baseUrl: process.env.NEXT_PUBLIC_SALESFORCE_BASE,
    ownerId: process.env.NEXT_PUBLIC_SALESFORCE_OWNER_ID,
    recordTypeId: process.env.NEXT_PUBLIC_SALESFORCE_RECORD_TYPE_ID,
    kbFeedbackRecordTypeId: process.env.NEXT_PUBLIC_SALESFORCE_KB_FEEDBACK_RECORD_TYPE_ID,
  },
  microservices: {
    baseUrl: process.env.NEXT_PUBLIC_MICROSERVICES_BASE,
    retryTime: process.env.NEXT_PUBLIC_MICROSERVICES_RETRY,
    staleTime: process.env.NEXT_PUBLIC_MICROSERVICES_STALE,
    timeoutTime: process.env.NEXT_PUBLIC_MICROSERVICES_TIMEOUT,
    interceptorRetryEnabled: process.env.NEXT_PUBLIC_MICROSERVICES_INTERCEPTOR_RETRY_ENABLED,
    interceptorRetryAmount: process.env.NEXT_PUBLIC_MICROSERVICES_INTERCEPTOR_RETRY_AMOUNT,
  },
  phoenixEdu: {
    baseUrl: process.env.NEXT_PUBLIC_PHOENIX_EDU_BASE,
    appCenterUrl: process.env.NEXT_PUBLIC_PHOENIX_EDU_APP_CENTER,
    appCenterPath: process.env.NEXT_PUBLIC_PHOENIX_EDU_NEW_APP_PATH,
  },
  linkedIn: {
    baseUrl: process.env.NEXT_PUBLIC_LINKEDIN_BASE,
    clientId: process.env.NEXT_PUBLIC_LINKEDIN_CLIENT_ID,
  },
  thirdParty: {
    linkedInEnabled: process.env.NEXT_PUBLIC_THIRD_PARTY_LINKEDIN_ENABLED,
    facebookEnabled: process.env.NEXT_PUBLIC_THIRD_PARTY_FACEBOOK_ENABLED,
  },
  chatBot: {
    serviceName: {
      default: process.env.NEXT_PUBLIC_CHATBOT_SERVICENAME_DEFAULT,
      academic: process.env.NEXT_PUBLIC_CHATBOT_SERVICENAME_ACADEMIC,
      financial: process.env.NEXT_PUBLIC_CHATBOT_SERVICENAME_FINANCIAL,
      technical: process.env.NEXT_PUBLIC_CHATBOT_SERVICENAME_TECHNICAL_SUPPORT,
    },
    genesysCXBUSUrl: `${process.env.NEXT_PUBLIC_MYPHX_BASE_URL}/genesys-chat/resources/js/cxbus.min.js`,
    genesysPluginPath: `${process.env.NEXT_PUBLIC_MYPHX_BASE_URL}/genesys-chat/resources/js/plugins/`,
    genesysCXWidgetsScript: `${process.env.NEXT_PUBLIC_MYPHX_BASE_URL}/genesys-chat/resources/js/widgets.min.js`,
    genesysWidgetsCustom: `${process.env.NEXT_PUBLIC_MYPHX_BASE_URL}/genesys-chat/resources/css/widgets_override.css`,
    genesysCXWidgetsStyles: `${process.env.NEXT_PUBLIC_MYPHX_BASE_URL}/genesys-chat/resources/css/widgets.min.css`,
    genesysCometDEnabled: process.env.NEXT_PUBLIC_FEATURE_GENESYS_COMETD_ENABLED === 'true',
    webChat_dataURL: `${process.env.NEXT_PUBLIC_CHATBOT_WEBCHAT_URL}/genesys/2/chat/Chat`,
    comet_URL: `${process.env.NEXT_PUBLIC_CHATBOT_WEBCHAT_URL}/genesys/cometd`,
    api_URL: `${process.env.NEXT_PUBLIC_CHATBOT_WEBCHAT_URL}/genesys/2/chat/Chat`,
    cobrowse_URL: `${process.env.NEXT_PUBLIC_CHATBOT_COBROWSE_URL}/cobrowse/`,
    cobrowseSrc: `${process.env.NEXT_PUBLIC_MYPHX_BASE_URL}/genesys-chat/resources/js/gcb.min.js`,
  },
  contentPipelineTemplateIds: {
    deansList: process.env.NEXT_PUBLIC_CP_TEMPLATE_DEANSLIST,
    presidentsList: process.env.NEXT_PUBLIC_CP_TEMPLATE_PRESIDENTSLIST,
    skill: process.env.NEXT_PUBLIC_CP_TEMPLATE_SKILL,
  },
  Portal: process.env.NEXT_PUBLIC_PORTAL_BASE,
  Builder: {
    apiKey: process.env.NEXT_PUBLIC_BUILDER_API_KEY,
    apiVersion: process.env.NEXT_PUBLIC_BUILDER_API_VERSION,
    contentApiBaseUrl: `${process.env.NEXT_PUBLIC_BUILDER_CONTENT_API_BASE_URL}${process.env.NEXT_PUBLIC_BUILDER_API_VERSION}/content/`,
  },
};

export default configs;
