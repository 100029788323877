import { baseApi, isQueryFnErrorResponse } from '@/store/queries';
import type { Services } from '@/services';

export const documentApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getSubmittedApplication: build.query({
      async queryFn(
        { applicationId, applicationType, token }:
        { applicationId: string, applicationType?: string, token?: string },
        { extra },
      ) {
        const { DocumentService } = extra as Services;
        try {
          const packetName = applicationType === 'REENROLL-I' ? 'REENROLL_PACKET' : undefined;
          const results = await DocumentService
            .setToken(token)
            .setServerSideBaseUrl()
            .getSubmittedApplicationHTML(applicationId, packetName);
          return { data: results.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
  }),
});

export const {
  useLazyGetSubmittedApplicationQuery,
} = documentApi;
