import {
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import { HYDRATE } from 'next-redux-wrapper';
import services, { Services } from '@/services';
import { baseApi, baseApi2 } from './queries';
import { alertsApi } from './queries/alerts';
import { contactsApi } from './queries/contacts';
import { coursesApi } from './queries/courses';
import { coursesApi as coursesApi2 } from './queries/v2/courses';
import { mockScenariosApi } from './queries/mock-scenarios';
import { notificationsApi } from './queries/notifications';
import { personsApi } from './queries/person';
import { personsApi as personsApi2 } from './queries/v2/person';
import { programsApi } from './queries/programs';
import { programsApi as programsApi2 } from './queries/v2/programs';
import { searchApi } from './queries/search';
import { studentApi } from './queries/student';
import { studentApi as studentApi2 } from './queries/v2/student';
import { workflowApi } from './queries/workflows';
import { attendancesApi as attendancesApi2 } from './queries/v2/attendances';
import { builderApi } from './queries/builder.io';
import { vouchersApi } from './queries/vouchers';
import { documentsApi } from './queries/documents';
import { academicApi } from './queries/academic';
import { applicationApi } from './queries/application';
import { documentApi } from './queries/document';
import { skillApi } from './queries/skill';
import { segmentApi } from './queries/segment';

// Implicit any due to buried types in RTK
export const middleware = (
  getDefaultMiddleware: (arg0: {
    thunk: { extraArgument: Services };
    serializableCheck: { ignoreActions: string[] };
  }) => any,
) => getDefaultMiddleware({
  thunk: {
    extraArgument: services,
  },
  serializableCheck: {
    ignoreActions: [HYDRATE, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
  },
})
  .concat(baseApi.middleware)
  .concat(baseApi2.middleware)
  .concat(academicApi.middleware)
  .concat(alertsApi.middleware)
  .concat(applicationApi.middleware)
  .concat(attendancesApi2.middleware)
  .concat(builderApi.middleware)
  .concat(contactsApi.middleware)
  .concat(coursesApi.middleware)
  .concat(coursesApi2.middleware)
  .concat(documentApi.middleware)
  .concat(documentsApi.middleware)
  .concat(mockScenariosApi.middleware)
  .concat(notificationsApi.middleware)
  .concat(personsApi?.middleware)
  .concat(personsApi2.middleware)
  .concat(programsApi.middleware)
  .concat(programsApi2.middleware)
  .concat(searchApi.middleware)
  .concat(skillApi.middleware)
  .concat(studentApi.middleware)
  .concat(studentApi2.middleware)
  .concat(vouchersApi.middleware)
  .concat(workflowApi.middleware)
  .concat(segmentApi.middleware);

export default middleware;
