import BaseService from '@/services/wrappers/service';

export default class SearchService extends BaseService {
  constructor(client) {
    super(client, 'search');
    this.getSearchResults = this.getSearchResults.bind(this);
  }

  async getSearchResults(body, collectionNameSelection, options) {
    const baseEndpointOverride = 'https://api.uopx.io/api/search';
    const useProdApi = process?.env?.NEXT_PUBLIC_SEARCH_STAGE_FIX_ENABLED?.toLowerCase() === 'true';

    return this.client.post(
      `${useProdApi ? baseEndpointOverride : this.baseEndpointUrl}/v1/${collectionNameSelection}/search`,
      body,
      {
        headers: {
          ...options?.headers,
          'Content-Type': 'application/json',
        },
        ...options,
      },
    );
  }
}
