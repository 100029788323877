import BaseService from '@/services/wrappers/service';

export default class LearningService extends BaseService {
  constructor(client) {
    super(client, 'learning');
    this.getActivitiesBySectionId = this.getActivitiesBySectionId.bind(this);
    this.getActivityGroupsBySectionId = this.getActivityGroupsBySectionId.bind(this);
    this.getAssessmentResultsByPersonIdAndSectionId = this
      .getAssessmentResultsByPersonIdAndSectionId.bind(this);
    this.getMembershipsByPersonId = this.getMembershipsByPersonId.bind(this);
    this.getMembershipsByPersonIdAndDateRange = this
      .getMembershipsByPersonIdAndDateRange.bind(this);
    this.getSectionBySourceId = this.getSectionBySourceId.bind(this);
    this.getAttendancesByMembershipId = this.getAttendancesByMembershipId.bind(this);
    this.getMembershipsBySourceIdTypeAndRole = this.getMembershipsBySourceIdTypeAndRole.bind(this);
    this.getSkillsByTemplateId = this.getSkillsByTemplateId.bind(this);
    this.getSkillsByProgramId = this.getSkillsByProgramId.bind(this);
    this.getSkillsForCourse = this.getSkillsForCourse.bind(this);
  }

  async getActivitiesBySectionId(sectionId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/activities?sourceId=${sectionId}&sourceType=SECTION`, // &gradable=true
    );
  }

  async getActivityGroupsBySectionId(sectionId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/course/activitygroups?sourceId=${sectionId}&sourceType=SECTION`,
    );
  }

  async getAssessmentResultsByPersonIdAndSectionId(personId, sectionId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/assessmentresults?personId=${personId}&sourceId=${sectionId}&sourceType=SECTION`, // &isPersProfile=true
    );
  }

  async getMembershipsByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/course/memberships?$filter=personId eq '${personId}'`,
    );
  }

  async getMembershipsByPersonIdAndDateRange(personId, min, max) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/course/memberships?$filter=personId eq '${personId}' and startDate ge ${min} and startDate le ${max}`,
    );
  }

  async getSectionBySourceId(sourceId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/course/section/${sourceId}`,
    );
  }

  async getAttendancesByMembershipId(membershipId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/attendances?membershipId=${membershipId}`,
    );
  }

  async getMembershipsBySourceIdTypeAndRole(sourceId, sourceType, roleType) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/course/memberships?$filter=sourceId eq '${sourceId}' and sourceType eq '${sourceType}' and roleType eq '${roleType}'`,
    );
  }

  async getSkillsByTemplateId(templateId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/course/curriculum?$templateid=${templateId}`,
    );
  }

  async getSkillsByProgramId(programId, version) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/course/program?$programid=${programId}v${version}`,
    );
  }

  async getSkillsForCourse(templateId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/course/curriculum?$templateid=${templateId}`,
    );
  }
}
