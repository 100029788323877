export type FetchRequestConfig = Omit<RequestInit, 'headers'> & {
  url?: string;
  headers?: Headers & { Authorization?: string };
  customAuth?: string;
  noAuth?: boolean;
};
export type FetchRequestBody = object | BodyInit;
export type RequestHandlerResponse = {
  httpVerb: string;
  data: any;
  url: string;
  headers: Headers;
  status: number;
  statusText: string;
  ok: boolean; // Honor backwards compatibility
  requestHeaders: {};
};
export type RequestHandlerResponseError = {
  error: {
    response: RequestHandlerResponse;
  };
};
export type FetchClientType = {
  get: (
    url: string,
    config?: FetchRequestConfig,
  ) => Promise<RequestHandlerResponse>;
  post: (
    url: string,
    body: FetchRequestBody,
    config?: FetchRequestConfig,
  ) => Promise<RequestHandlerResponse>;
  put: (
    url: string,
    body: FetchRequestBody,
    config?: FetchRequestConfig,
  ) => Promise<RequestHandlerResponse>;
  patch: (
    url: string,
    body: FetchRequestBody,
    config?: FetchRequestConfig,
  ) => Promise<RequestHandlerResponse>;
  delete: (
    url: string,
    config?: FetchRequestConfig,
  ) => Promise<RequestHandlerResponse>;
  request: (config: FetchRequestConfig) => Promise<RequestHandlerResponse>;
  applyAuthorization: (authorization: string) => void;
};

export type RequestInterceptorCallback = (
  requestConfig: FetchRequestConfig,
) => FetchRequestConfig;

export type ResponseInterceptorCallback = (
  response: Response,
  requestConfig: FetchRequestConfig,
  client: FetchClientType,
) => Response | null;

export class FetchClientError extends Error {
  response: RequestHandlerResponse;

  constructor(message: string, response: RequestHandlerResponse) {
    super(message);
    this.name = 'FetchClientError';
    this.response = response;
  }
}
