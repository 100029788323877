const microservicesBase = process?.env?.NEXT_PUBLIC_MICROSERVICES_BASE || '';

export const studentEndpoints = {
  getPrimaryProgramByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v1/program/membershipsdetails?$filter=personId eq '${personId}' and primary eq true`,
  getAllProgramsByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v1/program/membershipsdetails?$filter=personId eq '${personId}'`,
  getProgramProgressDataByProgramId: (
    programId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v1/program/detailprogression?$filter=studentProgramId eq ${programId}`,
  getMembershipsByPersonIdAndDateRange: (
    personId: string,
    min: string,
    max: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v2/course/memberships?$filter=personId eq '${personId}' and startDate ge ${min} and startDate le ${max}`,
  getLoaByPersonId: (
    personId: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v1/leaveofabsence?$filter=personId eq ${personId}`,
  getPLA: (
    irn: string,
    baseUrl = microservicesBase,
  ) => `${baseUrl}/student/v2/pla?$filter=irn eq '${irn}'`,
};

export default studentEndpoints;
