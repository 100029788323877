import { AnyAction, configureStore, EnhancedStore } from '@reduxjs/toolkit';
import storageSession from 'redux-persist/lib/storage/session';
import { Persistor } from 'redux-persist/es/types';
import { persistStore, persistReducer } from 'redux-persist';
import { reducers } from '@/store/reducers';
import { createWrapper } from 'next-redux-wrapper';
import { middleware } from './middleware';
import { mockScenariosApi } from './queries/mock-scenarios';
import { builderApi } from './queries/builder.io';
import { baseApi, baseApi2 } from './queries';

const DISABLE_SESSION_CACHE = false;

const makeStore = () => {
  // If we are SSR we cannot use redux-persist
  if (typeof window === 'undefined' || DISABLE_SESSION_CACHE) {
    return configureStore({
      reducer: reducers,
      middleware,
    });
  }

  const persistConfig = {
    key: 'myPhoenix',
    storage: storageSession,
    whitelist: [
      'session',
      'segment',
      baseApi.reducerPath,
      baseApi2.reducerPath,
    ],
    blacklist: [mockScenariosApi.reducerPath, builderApi.reducerPath],
  };

  const persistedReducer = persistReducer(persistConfig, reducers);

  const store = configureStore({
    reducer: persistedReducer,
    middleware,
  }) as EnhancedStore<any, AnyAction, any> & { reduxPersistor: Persistor };

  store.reduxPersistor = persistStore(store);
  return store;
};

export type ApplicationReduxStore = ReturnType<typeof makeStore>;

// eslint-disable-next-line jest/require-hook
// setupListeners(makeStore().dispatch);

const wrapper = createWrapper<ApplicationReduxStore>(makeStore);

export default wrapper;
