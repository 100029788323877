// TODO: Replace this temporary implementation of features.
const features = {
  feedbackLoopEnabled: process.env.NEXT_PUBLIC_FEATURE_FEEDBACK_LOOP_ENABLED === 'true',
  dashboardBadgeEnabled: process.env.NEXT_PUBLIC_FEATURE_DASHBOARD_BADGE_ENABLED === 'true',
  seocsEnabled: process.env.NEXT_PUBLIC_FEATURE_SEOCS_ENABLED === 'true',
  seocsTemplateIdKey: process.env.NEXT_PUBLIC_FEATURE_SEOCS_TEMPLATE_ID_KEY,
  purgeErrorOnReloadEnabled: process.env.NEXT_PUBLIC_FEATURE_PURGE_ON_ERROR_RELOAD_ENABLED === 'true',
  reenrollEnabled: process.env.NEXT_PUBLIC_FEATURE_REENROLL_ENABLED === 'true',
  reenrollIEnabled: process.env.NEXT_PUBLIC_FEATURE_REENROLL_I_ENABLED === 'true',
  notificationsV2Enabled: process.env.NEXT_PUBLIC_FEATURE_NOTIFICATIONS_V2_ENABLED === 'true',
  alumniReenrollEnabled: process.env.NEXT_PUBLIC_FEATURE_ALUMNI_REENROLL_ENABLED === 'true',
  nearGradReenrollEnabled: process.env.NEXT_PUBLIC_FEATURE_NEAR_GRAD_REENROLL_ENABLED === 'true',
  contactUsCookieEnabled: process.env.NEXT_PUBLIC_FEATURE_CONTACT_US_COOKIE_ENABLED === 'true',
  enableHomepage: process.env.NEXT_PUBLIC_FEATURE_ENABLE_HOMEPAGE === 'true',
  enableAcademicPlan: process.env.NEXT_PUBLIC_FEATURE_ENABLE_ACADEMIC_PLAN === 'true',
  mocksEnabled: process.env.NEXT_PUBLIC_MOCKS_ENABLED === 'true',
  dynamicLinksEnabled: process.env.NEXT_PUBLIC_FEATURE_DYNAMIC_BB_LINK_ENABLED === 'true',
  canaryTextEnabled: process.env.NEXT_PUBLIC_FEATURE_CANARY_TEXT_ENABLED === 'true',
  // SSR
  ssrRtkQueryHydrationEnabled: process.env.NEXT_FEATURE_SSR_HYDRATE_RTK === 'true',
  // Debugging
  performanceMarksEnabled: process.env.NEXT_PUBLIC_FEATURE_PERFORMANCE_MARKS_ENABLED === 'true',
};

enum FeatureVariableKeys {
  AttendanceTileBlackoutEndDate = 'attendance-tile-blackout-end-date',
  AttendanceTileEnabledCourses = 'attendance-tile-enabled-courses',
  loaMyphoenixRollout = 'loa-myphoenix-rollout',
  CbeEngagementTileBlackoutEndDate = 'cbe-engagement-tile-blackout-end-date',
  DaEngagementTileBlackoutEndDate = 'da-engagement-tile-blackout-end-date',
}

export default features;

export { FeatureVariableKeys };
