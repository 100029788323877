import BaseService from '@/services/wrappers/service';
import resolveCBEPostData from '@/myphoenix/utils/cbe-postdata';
import resolveESTGraduationPostData from '@/myphoenix/utils/estimate-date-postdata';

export default class StudentService extends BaseService {
  constructor(client) {
    super(client, 'student');
    this.getStatusesByPersonId = this.getStatusesByPersonId.bind(this);
    this.getPrimaryProgramByPersonId = this.getPrimaryProgramByPersonId.bind(this);
    this.getAllProgramsByPersonId = this.getAllProgramsByPersonId.bind(this);
    this.getStudentLOAdataByPersonId = this.getLoaByPersonId.bind(this);
    this.getProgramProgressDataByProgramId = this.getProgramProgressDataByProgramId.bind(this);
    this.getCourseOutcomes = this.getCourseOutcomes.bind(this);
    this.getCBEDropDate = this.getCBEDropDate.bind(this);
    this.getESTGraduationDate = this.getESTGraduationDate.bind(this);
    this.getAcademicCatalogList = this.getAcademicCatalogList.bind(this);
    this.getStudentLOAContainmentPeriod = this.getStudentLOAContainmentPeriod.bind(this);
    this.getStudentLOAHistory = this.getStudentLOAHistory.bind(this);
    this.getStudentTransferCreditsDataByPersonId = this.getStudentTransferCreditsDataByPersonId
      .bind(this);
  }

  async getStatusesByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/statuses?$filter=personId eq '${personId}'`,
    );
  }

  async getPrimaryProgramByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/program/membershipsdetails?$filter=personId eq '${personId}' and primary eq true`,
    );
  }

  async getAllProgramsByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/program/membershipsdetails?$filter=personId eq '${personId}'`,
    );
  }

  async getLoaByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/leaveofabsence?$filter=personId eq ${personId}`,
    );
  }

  async getProgramProgressDataByProgramId(programId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/program/detailprogression?$filter=studentProgramId eq ${programId}`,
    );
  }

  async getCourseOutcomes(programId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/course/memberships/outcome?csph=${programId}`,
    );
  }

  async getCBEDropDate(persondId, programCode, programVersion) {
    return this.client.post(
      `${this.baseEndpointUrl}/v1/rules/stateless/cbeAnticipatedDropDate`,
      resolveCBEPostData(persondId, programCode, programVersion),
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
  }

  async getESTGraduationDate(requestBody) {
    return this.client.post(
      `${this.baseEndpointUrl}/v1/stateless/ruleset/estimatedGraduationDate`,
      resolveESTGraduationPostData(requestBody),
    );
  }

  async getAcademicCatalogList(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/academiccatalogs/${personId}`,
    );
  }

  async getStudentTransferCreditsDataByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/course/transferCreditDetails?personId=${personId}`,
    );
  }

  async postStudentLoa(createLoaRequest) {
    return this.client.post(
      `${this.baseEndpointUrl}/v1/loa/create`,
      createLoaRequest,
      {
        headers: {
          'Content-type': 'application/json',
        },
        timeout: 0,
      },
    );
  }

  async postCancelStudentLoa(cancelLoaRequest) {
    return this.client.post(
      `${this.baseEndpointUrl}/v1/loa/cancel`,
      cancelLoaRequest,
      {
        headers: {
          'Content-type': 'application/json',
        },
        timeout: 0,
      },
    );
  }

  async getStudentLOAContainmentPeriod(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/loa/leaveofabsence?$filter=personid eq ${personId}`,
    );
  }

  async getStudentLOAHistory(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/loa/history?$filter=personid eq ${personId}`,
    );
  }
}
