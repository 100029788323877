import { baseApi, isQueryFnErrorResponse, isQueryFnExpandedErrorResponse } from '@/store/queries';
import { getTransformedProgramsData } from '@/store/helpers/contacts';
import { Services } from '@/services';

export const programsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPrograms: build.query({
      async queryFn(
        { personId, programId, token },
        { extra },
      ) {
        try {
          const { ProgramsService, StudentService } = extra as Services;
          const programsResponse = await StudentService.setToken(token)
            .setServerSideBaseUrl().getAllProgramsByPersonId(personId);

          const programsData = getTransformedProgramsData(programsResponse.data);
          const programs = await Promise.all(programsData
            .map((program: { code: string, version: string }) => (ProgramsService.setToken(token)
              .setServerSideBaseUrl().getTemplateByProgramIdAndVersion(
                program.code,
                program.version,
              ))));
          const primaryProgramIndex = programsData.findIndex(
            (program: { isPrimary: boolean }) => program.isPrimary,
          );
          const primaryProgram = programsData?.[primaryProgramIndex] || {};
          const primaryProgramData = programs?.[primaryProgramIndex]?.data?.[0] || {};
          const formattedPrimaryProgram = primaryProgram?.code ? {
            ...primaryProgram,
            type: primaryProgramData.programType || '',
            externalProgramType: primaryProgramData.externalProgramType || '',
            qualificationLevel: primaryProgramData.programLevel || '',
            name: primaryProgramData.displayName || '',
            description: primaryProgramData.textDescription || '',
            cbeProgram: primaryProgramData.cbeProgram || '',
            cbeDaProgram: primaryProgramData.cbeDaProgram || '',
          } : primaryProgram;

          const specificProgramIndex = programsResponse.data.findIndex(
            (program: { id: string }) => program.id === programId,
          );
          const specificProgram = programsResponse?.data?.[specificProgramIndex] || {};
          const specificProgramData = programs?.[specificProgramIndex]?.data?.[0] || {};
          const formattedSpecificProgram = specificProgramData ? {
            ...specificProgram,
            type: specificProgramData.programType || '',
            qualificationLevel: specificProgramData.programLevel || '',
            name: specificProgramData.displayName || '',
            description: specificProgramData.textDescription || '',
            cbeProgram: specificProgramData.cbeProgram || '',
            cbeDaProgram: specificProgramData.cbeDaProgram || '',
          } : specificProgram;

          const programsList = programs.map((programResponse) => programResponse.data[0]);

          return {
            data: {
              personId,
              primaryProgram: formattedPrimaryProgram,
              formattedSpecificProgram,
              programsList,
            },
          };
        } catch (error) {
          return {
            error: {
              message: error?.response?.statusText,
              statusCode: error?.response?.status,
            },
          };
        }
      },
    }),
    getProgramProgressDataByProgramId: build.query({
      async queryFn(
        { programId, token },
        { extra },
      ) {
        try {
          const { StudentService } = extra as Services;
          // Get all memberships that student belongs to
          const results = await StudentService.setToken(token)
            .setServerSideBaseUrl().getProgramProgressDataByProgramId(
              programId,
            );
          const creditsSummary = results?.data?.programProgress?.creditsSummary;
          return {
            data: {
              ...(results?.data || {}),
              isDegreeAudit: true,
              creditsSummary,
            },
          };
        } catch (error: unknown) {
          if (isQueryFnExpandedErrorResponse(error)) {
            const { response: { status, data: { message } = {} } = {} } = error;
            if (status === 500 && message.indexOf('Service is not available for program') > -1) {
              return { data: { isDegreeAudit: false } };
            }
          }
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: {
              message: statusText,
              statusCode: status,
            } };
          }
          throw error;
        }
      },
    }),
    getSkillsByProgramId: build.query({
      async queryFn(
        { programId, version, token }: { programId: string, version: string, token?: string },
        { extra },
      ) {
        try {
          const { LearningService } = extra as Services;
          const results = await LearningService.setToken(token)
            .setServerSideBaseUrl()
            .getSkillsByProgramId(programId, version);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getProgramInfoByProgramOfferingId: build.query({
      async queryFn(
        { programOfferingId, token }: { programOfferingId: string, token?: string },
        { extra },
      ) {
        try {
          const { ProgramsService } = extra as Services;
          const results = await ProgramsService.setToken(token)
            .setServerSideBaseUrl().getProgramInfoByProgramOfferingId(programOfferingId);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
    getLoaReturnDatesByIrn: build.query({
      async queryFn(
        { irn, loaStartDate, token }:
        { irn: string, loaStartDate: string, token?: string },
        { extra },
      ) {
        const { ProgramsServiceV1 } = extra as Services;
        try {
          const results = await ProgramsServiceV1.setToken(token)
            .setServerSideBaseUrl()
            .getLoaReturnDatesByIrn(irn, loaStartDate);
          return { data: results?.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      providesTags: ['Loa'],
    }),
  }),
});

export const {
  useGetProgramsQuery,
  useGetProgramProgressDataByProgramIdQuery,
  useGetSkillsByProgramIdQuery,
  useGetProgramInfoByProgramOfferingIdQuery,
  useGetLoaReturnDatesByIrnQuery,
} = programsApi;
