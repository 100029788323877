import PropTypes from 'prop-types';
import { signOut } from 'next-auth/react';
import { SIGN_OUT_CALLBACK } from '@/helpers/auth/constants';
import { logoutOfExternalApplication,
  constructExternalApplicationLogoutUrl } from '@/helpers/auth';
import {
  LinkWrapper, StyledLink, StyledIcon,
} from '@/components/atoms/logout-icon-link/LogoutIconLink.styles';
import configs from '@/helpers/config';

const { ecampus, multimedia } = configs;

function LogoutIconLink({ logoutText }) {
  async function logout() {
    return Promise.all([
      logoutOfExternalApplication(
        constructExternalApplicationLogoutUrl(
          multimedia.baseUrl,
          multimedia.logoutPath,
          multimedia.logoutEnabled,
        ),
      ),
      logoutOfExternalApplication(
        constructExternalApplicationLogoutUrl(
          ecampus.baseUrl,
          ecampus.logoutPath,
          ecampus.logoutEnabled,
        ),
      ),
      signOut({
        callbackUrl: SIGN_OUT_CALLBACK,
      }),
    ]);
  }
  return (
    <LinkWrapper>
      <StyledLink
        onClick={() => logout()}
        href="#"
      >
        <StyledIcon
          id="header-logout-icon"
          icon="icon-logout"
        />
        <span>
          {logoutText}
        </span>
      </StyledLink>
    </LinkWrapper>
  );
}

LogoutIconLink.propTypes = {
  logoutText: PropTypes.string,
};

LogoutIconLink.defaultProps = {
  logoutText: 'Logout',
};

export default LogoutIconLink;
