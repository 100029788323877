// TODO: Remove the whole rule when we move all the code to TS
/* eslint-disable react/require-default-props */
import { styled } from '@mui/material/styles';
import icons, { AppIcon, IconType } from '@/constants/icons';
import React from 'react';

type IconProps = {
  id: string,
  className?: string,
  icon: AppIcon,
  ariaLabel?: string,
  color?: string,
  ['aria-label']?: string,
};

type StyledIconProps = {
  id: string,
  className?: string,
  ariaLabel?: string,
  color?: string,
  title: string,
  ['aria-label']: string,
};

function Icon({
  id, className, icon, ariaLabel, color, ...props
}: IconProps) {
  // To add icon, add svg to public/icons and append to object in @/constants/icons.
  // The icon should be retrievable with it's respective key <Icon icon='icon-key' />

  const IconComponent = icons[IconType[icon]];
  const trimmedIconName = icon.substring(5);

  const StyledIcon = styled(IconComponent)<StyledIconProps>(() => ({
    [`&.myphoenix-icon.${icon}-fill`]: {
      fill: color,
    },
  }));

  return (
    <StyledIcon
      {...props}
      id={id}
      className={`${icon} myphoenix-icon ${icon}-fill ${className}`}
      aria-hidden={!props['aria-label']}
      title={trimmedIconName}
      aria-label={ariaLabel !== undefined ? ariaLabel : trimmedIconName}
    />
  );
}

export default Icon;
