import { baseApi } from '@/store/queries';
import { DateTime } from 'luxon';

const RANGE = {
  min: process.env.NEXT_PUBLIC_LEARNING_DEFAULT_RANGE_MINIMUM || -455,
  max: process.env.NEXT_PUBLIC_LEARNING_DEFAULT_RANGE_MAXIMUM || 180,
};

const returnErrorHelper = (results) => {
  const { error: { response: { statusText, status } = {} } = {} } = results;
  return { error: { message: statusText, statusCode: status } };
};

export const coursesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMemberships: build.query({
      async queryFn(
        { personId, token },
        { extra: { StudentServiceV2 } },
      ) {
        // Get all memberships that student belongs to
        const results = await StudentServiceV2.setToken(token)
          .setServerSideBaseUrl().getMembershipsByPersonIdAndDateRange(
            personId,
            DateTime.now().plus({ days: RANGE.min }).toISODate().toString(),
            DateTime.now().plus({ days: RANGE.max }).toISODate().toString(),
          ).catch((error) => ({ error }));

        if (results.error) {
          return returnErrorHelper(results);
        }

        const filteredMemberships = results?.data?.memberships
          ?.filter(({ startDate, endDate, courseOfferingType, participantId } = {}) => (
            startDate && endDate && courseOfferingType !== 'TT' && participantId
          ));

        const membershipsWithId = filteredMemberships?.map((membership) => ({
          ...membership,
          id: membership.id || `OSIRIS:${membership?.participantId}`,
        }));
        return { data: membershipsWithId };
      },
    }),
    getMembershipSourceIds: build.query({
      async queryFn(
        { personId, token },
        { extra: { StudentServiceV2 } },
      ) {
        // Get all memberships that student belongs to
        const results = await StudentServiceV2.setToken(token)
          .setServerSideBaseUrl().getMembershipsByPersonIdAndDateRange(
            personId,
            DateTime.now().plus({ days: RANGE.min }).toISODate().toString(),
            DateTime.now().plus({ days: RANGE.max }).toISODate().toString(),
          ).catch((error) => ({ error }));
        if (results.error) {
          return returnErrorHelper(results);
        }

        const { data: { memberships } } = results;
        const systemId = memberships?.[0]?.externalSystemIds?.[0]?.systemId;

        const filteredMemberships = results?.data?.memberships
          ?.filter(({ startDate, endDate, courseOfferingType } = {}) => (
            startDate && endDate && courseOfferingType !== 'TT'
          ));

        const sourceIds = filteredMemberships?.flatMap((membership) => membership.sourceId);
        return { data: { sourceIds, systemId } };
      },
    }),
    getCoursesBySourceIds: build.query({
      async queryFn(
        { sourceIds, token },
        { extra: { LearningService } },
      ) {
        // Exit early if we don't have any sourceIds
        if ((sourceIds?.length || 0) === 0) {
          return { data: [] };
        }

        const results = await Promise.all(
          sourceIds.map(async (sourceId) => LearningService.setToken(token)
            .setServerSideBaseUrl().getSectionBySourceId(sourceId)),
        ).catch((error) => ({ error }));

        if (results.error) {
          return returnErrorHelper(results);
        }

        const data = results?.map((result) => result.data);

        return { data };
      },
    }),
    getInstructorsBySourceIds: build.query({
      async queryFn(
        { sourceIds, token },
        { extra: { LearningService, PersonsService, ContactsService } },
      ) {
        // Exit early if we don't have any sourceIds
        if ((sourceIds?.length || 0) === 0) {
          return { data: [] };
        }

        const instructorResults = await Promise.all(
          sourceIds.map(async (sourceId) => LearningService.setToken(token)
            .setServerSideBaseUrl().getMembershipsBySourceIdTypeAndRole(sourceId, 'SECTION', 'IN').then((result) => ({ sourceId,
              data: result.data }))),
        ).catch((error) => ({ error }));

        if (instructorResults.error) {
          return returnErrorHelper(instructorResults);
        }

        try {
          const instructorDetailsResponse = await Promise.all(
            instructorResults.map(async (instructorResult) => {
              const { personId, id: membershipId } = instructorResult.data?.memberships?.find((membership) => membership?.roleType?.toLowerCase() === 'instructor' && membership?.subRoleType?.toLowerCase() === 'primary') || {};

              if (!personId) return null;

              const names = await PersonsService.setToken(token)
                .setServerSideBaseUrl().getNamesByPersonId(personId);
              const contact = await ContactsService.setToken(token)
                .setServerSideBaseUrl().getEmails(personId);
              const name = names?.data?.find((instructorName) => instructorName?.type?.toLowerCase() === 'official') || {};
              const email = contact.data?.find((contactEmail) => contactEmail.primary === 'true')?.emailAddress;
              return {
                ...name,
                email,
                membershipId,
              };
            }),
          );

          const data = instructorDetailsResponse.filter((instructor) => instructor !== null);

          return { data };
        } catch (error) {
          return returnErrorHelper({ error });
        }
      },
    }),
    getCoursesByMemberships: build.query({
      async queryFn(
        { personId, token },
        { extra: { StudentServiceV2, LearningService } },
      ) {
        // Get all memberships that student belongs to
        const membershipResults = await StudentServiceV2.setToken(token)
          .setServerSideBaseUrl().getMembershipsByPersonIdAndDateRange(
            personId,
            DateTime.now().plus({ days: RANGE.min }).toISODate().toString(),
            DateTime.now().plus({ days: RANGE.max }).toISODate().toString(),
          ).catch((error) => ({ error }));

        if (membershipResults.error) {
          return returnErrorHelper(membershipResults);
        }

        const { data: { memberships = [] } = {} } = membershipResults;

        if (!memberships?.length) return { data: {} };

        const filteredMemberships = memberships
          ?.filter(({ startDate, endDate, courseOfferingType } = {}) => (
            startDate && endDate && courseOfferingType !== 'TT'
          ));

        const membershipsWithId = filteredMemberships?.map((membership) => ({
          ...membership,
          id: membership.id || `OSIRIS:${membership?.participantId}`,
        }));

        const sourceIds = membershipsWithId?.flatMap((membership) => membership.sourceId);

        // Exit early if we don't have any sourceIds
        if (!sourceIds?.length) return { data: {} };

        const courseResults = await Promise.all(
          sourceIds.map(async (sourceId) => LearningService.setToken(token)
            .setServerSideBaseUrl().getSectionBySourceId(sourceId)),
        ).catch((error) => ({ error }));

        if (courseResults.error) {
          return returnErrorHelper(courseResults);
        }

        const courses = courseResults?.map((result) => result.data);

        // Mesh course data with membership data
        const byMembership = membershipsWithId.reduce((prev, current) => {
          if (current?.sourceId) {
            return {
              ...prev,
              [current.sourceId]: current,
            };
          }
          return prev;
        }, {});

        const filledCourses = courses.map((course) => {
          const membership = byMembership[course.id];
          const modifiedCourse = {
            ...course,
            statusSubCode: membership?.statusSubCode || membership?.status,
            membershipId: membership?.id,
            sectionId: course.id,
            startDate: membership?.startDate || course.startDate,
            endDate: membership?.endDate || course.endDate,
            grade: membership?.grade || '',
          };
          return modifiedCourse;
        });

        return { data: { courses: filledCourses, memberships: membershipsWithId } };
      },
    }),
  }),
});

export const {
  useGetMembershipsQuery,
  useGetMembershipSourceIdsQuery,
  useGetCoursesBySourceIdsQuery,
  useGetInstructorsBySourceIdsQuery,
  useGetCoursesByMembershipsQuery,
} = coursesApi;
