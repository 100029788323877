import { baseApi } from '@/store/queries';
import { getTransformedProgramsData, getContactList } from '@/store/helpers/contacts';

export const contactsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPersonPrimaryOrDefaultContacts: build.query({
      async queryFn({ personId, token, baseUrl }, { extra: { ContactsService } }) {
        const results = await Promise.all([
          ContactsService.setToken(token)
            .setServerSideBaseUrl(baseUrl).getPhones(personId),
          ContactsService.setToken(token)
            .setServerSideBaseUrl(baseUrl).getEmails(personId),
        ]).catch((error) => ({ error }));

        if (results.error) {
          const { error: { response: { statusText, status } = {} } = {} } = results;
          return { error: { message: statusText, statusCode: status } };
        }

        const [phones, emails] = results;
        const primaryOrDefaultPhone = phones.data?.find((phone) => phone.primary === 'true')
          || (phones.data || [])[0];
        const primaryOrDefaultEmail = emails.data?.find((email) => email.primary === 'true')
          || (emails.data || [])[0];

        return { data: { phone: primaryOrDefaultPhone, email: primaryOrDefaultEmail } };
      },
    }),
    getUniversityContacts: build.query({
      async queryFn(
        { personId, token },
        { extra: { ContactsService, StudentService } },
      ) {
        try {
          const programsResponse = await StudentService.setToken(token)
            .setServerSideBaseUrl().getAllProgramsByPersonId(personId);

          const programsData = getTransformedProgramsData(programsResponse.data);
          const primaryProgramId = programsData.filter((program) => program.isPrimary)[0].code;

          const contact = await ContactsService.setToken(token)
            .setServerSideBaseUrl().getStaffContacts(personId, primaryProgramId);

          const {
            contacts, enrollmentRep, academicCounselor,
          } = getContactList(contact.data);
          return {
            data: {
              personId,
              contacts,
              enrollmentRep,
              academicCounselor,
            },
          };
        } catch (error) {
          return {
            error: {
              message: error?.response?.statusText,
              statusCode: error?.response?.status,
            },
          };
        }
      },
    }),
  }),
});

export const {
  useGetUniversityContactsQuery,
  useGetPersonPrimaryOrDefaultContactsQuery,
} = contactsApi;
