import BaseService from '@/services/wrappers/service';

export default class StudentService extends BaseService {
  constructor(client) {
    super(client, 'student');
    this.getPLA = this.getPLA.bind(this);
    this.uploadDocument = this.uploadDocument.bind(this);
    this.deleteDocument = this.deleteDocument.bind(this);
    this.submitApplication = this.submitApplication.bind(this);
    this.getMembershipsByPersonId = this.getMembershipsByPersonId.bind(this);
    this.getMembershipsByPersonIdAndDateRange = this.getMembershipsByPersonIdAndDateRange.bind(
      this,
    );
  }

  async getPLA(irn) {
    return this.client.get(
      `${this.baseEndpointUrl}/v2/pla?$filter=irn eq ${irn}`,
    );
  }

  async uploadDocument(irn, file) {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('IRN', irn);
    return this.client.post(
      `${this.baseEndpointUrl}/v2/pla/documents`,
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        // taking off timeout limit for uploading files for now until we get a feel for how long
        // bigger files take
        timeout: 0,
      },
    );
  }

  async deleteDocument(irn, fileId) {
    return this.client.delete(
      `${this.baseEndpointUrl}/v2/pla/documents/${fileId}?IRN=${irn}`,
    );
  }

  async submitApplication(irn, applicationId) {
    return this.client.post(
      `${this.baseEndpointUrl}/v2/pla/applications/${applicationId}?IRN=${irn}`,
    );
  }

  async getMembershipsByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v2/course/memberships?$filter=personId eq '${personId}'`,
    );
  }

  async getMembershipsByPersonIdAndDateRange(personId, min, max) {
    return this.client.get(
      `${this.baseEndpointUrl}/v2/course/memberships?$filter=personId eq '${personId}' and startDate ge ${min} and startDate le ${max}`,
    );
  }
}
