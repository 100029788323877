import BaseService from '@/services/wrappers/service';

export default class SagasService extends BaseService {
  constructor(client) {
    super(client, 'sagas');
    /**
     * Rajani says this header is necessary for all sagas calls to improve performance
     * by routing the calls, to an alternate 'blue' farm >.>
     */
    this.config = { headers: { 'out-of-the-blue': 1, 'Content-Type': 'application/json' } };
    this.getTasksByPersonId = this.getTasksByPersonId.bind(this);
    this.getApplicationWorkflowByPersonId = this.getApplicationWorkflowByPersonId.bind(this);
    this.updateTask = this.updateTask.bind(this);
  }

  async getTasksByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/persontasks/${personId}?$filter=context eq '/tenantid:831D8B107020BBEBE0400F0A32207789/taxonomy:tasks/contexts/home'`,
      this.config,
    );
  }

  async getApplicationWorkflowByPersonId(personId) {
    return this.client.get(
      `${this.baseEndpointUrl}/v1/workflow/ADMISSION/person/${personId}?includeTasks=true&includeClosed=true`,
      this.config,
    );
  }

  async updateTask(personId, taskId, payload) {
    return this.client.put(
      `${this.baseEndpointUrl}/v1/persontask/${personId}/${taskId}`,
      payload,
      this.config,
    );
  }
}
