import { getSession } from 'next-auth/react';
import { getItem as getFromSessionStorage } from '@/helpers/session-storage';
import { MOCK_SCENARIO_KEY } from '@/helpers/session-storage/constants';
import { TOKEN_STORAGE_KEY, PERSON_ID_STORAGE_KEY, EMAIL_ID_STORAGE_KEY, CUSTOM_TOKEN_STORAGE_KEY } from './constants';

// Cleared for use with Brent
export const setUserInSessionStorage = (user) => {
  if (typeof window !== 'undefined') {
    sessionStorage.setItem(TOKEN_STORAGE_KEY, user?.token);
    sessionStorage.setItem(PERSON_ID_STORAGE_KEY, user?.personId);
    sessionStorage.setItem(EMAIL_ID_STORAGE_KEY, user?.email);
  }
};

export const getTokenFromSessionStorage = () => getFromSessionStorage(TOKEN_STORAGE_KEY);

export const getCustomTokenFromSessionStorage = (
  () => getFromSessionStorage(CUSTOM_TOKEN_STORAGE_KEY)
);

export const getPersonIdFromSessionStorage = () => {
  const scenarioId = getFromSessionStorage(MOCK_SCENARIO_KEY) || null;
  return scenarioId || getFromSessionStorage(PERSON_ID_STORAGE_KEY);
};

export const getEmailIdFromSessionStorage = (
  () => getFromSessionStorage(EMAIL_ID_STORAGE_KEY)
);

const getUserProperty = async (storageKey, propertyKey, context) => {
  const sessionStorageItem = getFromSessionStorage(storageKey);

  // Try and pull from session storage to avoid a server side check
  if ((sessionStorageItem || '') !== '') {
    return sessionStorageItem;
  }

  // Will always be hit if a server side call is made
  const session = await getSession(context);

  // Consumer will need to check token and re-auth if needed
  return session?.user?.[propertyKey];
};

/*
Preferred method to use for any http request as it handles token retrieval from client or server.
Ensure context is passed from getServerSideProps as an example
or req as an object { req } if api server side.
*/
export const getToken = async (context) => getUserProperty(TOKEN_STORAGE_KEY, 'token', context);

/*
Preferred method to use for any http request as it handles token retrieval from client or server.
Ensure context is passed from getServerSideProps as an example
or req as an object { req } if api server side.
*/
export const getPersonId = async (context) => getUserProperty(PERSON_ID_STORAGE_KEY, 'personId', context);

/*
Ensure context is passed from getServerSideProps as an example
or req as an object { req } if api server side.
*/
export const isUserLoggedIn = async (context) => {
  const session = await getSession(context);

  return !!session?.user;
};

/*
Server side specific method to get user session data in one trip.
Ensure context is passed from getServerSideProps as an example
or req as an object { req } if api server side.
*/
export const getSessionData = async (context) => {
  const session = await getSession(context);
  return {
    isUserLoggedIn: !!session?.user,
    token: session?.user?.token,
    personId: session?.user?.personId,
  };
};

export async function logoutOfExternalApplication(url, method = 'GET') {
  if (url) {
    try {
      return await fetch(url, {
        method,
        credentials: 'include',
        timeout: 5000,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      return console.error(error);
    }
  }
  return null;
}

export const constructExternalApplicationLogoutUrl = (baseUrl, logoutUrl, logoutEnabled) => {
  if (logoutEnabled?.toLowerCase() === 'true' && baseUrl && logoutUrl) {
    return `${baseUrl}${logoutUrl}`;
  }
  return null;
};
