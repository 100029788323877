import NotificationAlert from '@/components/atoms/alert';
import NotificationBell from '@/components/atoms/notification-bell';
import { logonEventRedirect } from '@/components/molecules/header-notification/utils';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import { getItem } from '@/helpers/session-storage';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import { useGetLatestApplicationIdQuery } from '@/store/queries/workflows';
import {
  useGetNotificationsQuery,
  useGetTasksQuery,
  useGetEventsQuery,
} from '@/store/queries/notifications';
import { filteredUnreadNonLogonEvents } from '@/store/helpers/notifications';

const getNotificationCount = (
  tasks: { assignedTasks: any[]; enrollmentTasks: any[] },
  notifications: any[],
) => (tasks?.assignedTasks?.length || 0)
+ (tasks?.enrollmentTasks?.length || 0)
  + (filteredUnreadNonLogonEvents(notifications)?.length || 0);

function HeaderNotification({
  parentComponentName,
}: {
  parentComponentName: string;
}) {
  const logonEventShouldBeBypassed = getItem('logon-event-bypassed');
  const {
    data: { applicationId } = {},
    isLoading: isApplicationQueryLoading,
  } = useGetLatestApplicationIdQuery({
    personId: getPersonIdFromSessionStorage(),
  });
  const { data: tasks } = useGetTasksQuery(
    { personId: getPersonIdFromSessionStorage(), applicationId },
    { skip: isApplicationQueryLoading },
  );
  const { data: events } = useGetEventsQuery({
    personId: getPersonIdFromSessionStorage(),
  });
  const { data: notifications } = useGetNotificationsQuery({
    personId: getPersonIdFromSessionStorage(),
  });

  const notificationCount = getNotificationCount(tasks, notifications);

  const showAlertNotification = events?.loginCount > 0 && !logonEventShouldBeBypassed;

  return (
    <>
      {/* Should we add the loginEvent counts here as well? */}
      <NotificationBell
        badgeContent={notificationCount}
        href={makeAbsoluteUrl('/message-center.html')}
        parentComponentName={parentComponentName}
      />
      <NotificationAlert
        open={showAlertNotification}
        title="You have an important message."
        content="Please review and take the appropriate action to move forward."
        buttonText="Continue to message"
        onClick={logonEventRedirect}
      />
    </>
  );
}

export default HeaderNotification;
