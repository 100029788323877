import {
  RequestInterceptorCallback,
  FetchRequestConfig,
  ResponseInterceptorCallback,
  FetchClientType,
  RequestHandlerResponse,
  FetchRequestBody,
  FetchClientError,
} from './fetch-types';

// ? Does this need to be more dynamic
const DEFAULT_FETCH_TIMEOUT = (() => {
  const timeout = Number(process.env.NEXT_PUBLIC_MICROSERVICES_TIMEOUT);
  return Number.isNaN(timeout) ? 15000 : timeout;
})();

// https://github.com/node-fetch/timeout-signal/blob/main/index.js
// No current support in implementation for AbortSignal.timeout so this is a patch
// https://github.com/whatwg/dom/pull/1032 and https://github.com/w3ctag/design-reviews/issues/711
const requestTimeoutHandler = (timeout: number) => {
  const controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), timeout);
  // Allow Node.js processes to exit early if only the timeout is running
  timeoutId?.unref?.();
  return controller.signal;
};

const requestConfigAppender = (
  callbacks: RequestInterceptorCallback[],
  baseConfig: FetchRequestConfig,
): FetchRequestConfig => callbacks.reduce((accumulator, interceptor) => {
  const updatedConfig = interceptor(accumulator);
  return { ...accumulator, ...updatedConfig };
}, baseConfig);

const requestFailureHandler = async (
  callbacks: ResponseInterceptorCallback[],
  requestConfig: FetchRequestConfig,
  response: Response,
  client: FetchClientType,
) => {
  for (let i = 0; i < callbacks.length; i += 1) {
    const callbackResult = callbacks[i](response, requestConfig, client);
    if (callbackResult) {
      return callbackResult;
    }
  }
  return null;
};

const convertPayloadIfJSON = (payload: string) => {
  if (!payload) return {};
  try {
    return JSON.parse(payload);
  } catch (e) {
    return payload;
  }
};

const buildResponse = async (
  request: Promise<Response> | Response,
  httpVerb: string,
  requestHeaders = {},
): Promise<RequestHandlerResponse> => {
  const resolvedRequest = await request;
  return {
    httpVerb,
    data: convertPayloadIfJSON(await resolvedRequest.text?.()), // Fix if text is not a function
    url: resolvedRequest.url,
    headers: resolvedRequest.headers,
    status: resolvedRequest.status,
    statusText: resolvedRequest.statusText,
    ok: resolvedRequest.ok,
    requestHeaders,
  };
};

const requestHandler = async (
  client: FetchClientType,
  url: string,
  body?: BodyInit,
  config?: FetchRequestConfig,
  verb?: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  {
    requestInterceptors,
    responseInterceptors,
    preSetAuthorization,
  }: {
    requestInterceptors?: RequestInterceptorCallback[];
    responseInterceptors?: ResponseInterceptorCallback[];
    preSetAuthorization?: string;
  } = {},
) => {
  const requestConfig = requestConfigAppender(requestInterceptors, {
    ...config,
    credentials: 'omit',
    headers: {
      ...config?.headers,
      Authorization: config?.headers?.Authorization || preSetAuthorization,
    },
    signal: config?.signal || requestTimeoutHandler(DEFAULT_FETCH_TIMEOUT),
    ...((url || '') !== '' && { url }),
    ...((verb || '') !== '' && { method: verb }),
    ...((body || '') !== '' && { body }),
  });

  let response: Response;

  try {
    response = await fetch(requestConfig.url, requestConfig);
  } catch (exception) {
    // TimeoutError and AbortError come from either timeout or user aborting the request default bad
    response = 'ok' in exception
      ? exception
      : new Response(null, {
        status: 408,
        statusText: 'Request Timeout',
      });
  }

  if (response.ok) {
    return response;
  }

  const res = await requestFailureHandler(
    responseInterceptors,
    requestConfig,
    response,
    client,
  );

  if (res === null) {
    const errorResponse = await buildResponse(
      response,
      verb || config.method,
      requestConfig.headers,
    );
    throw new FetchClientError(response.statusText, errorResponse);
  }

  return res;
};

export default class FetchClient {
  requestInterceptors: RequestInterceptorCallback[];

  responseInterceptors: ResponseInterceptorCallback[];

  authorization: string;

  constructor() {
    this.requestInterceptors = [];
    this.responseInterceptors = [];
    this.authorization = null;
    this.client = this.client.bind(this);
  }

  applyRequestInterceptor(callback: RequestInterceptorCallback) {
    // "strictNullChecks": true would be better suited for this as this will hide issues
    if (callback) this.requestInterceptors.push(callback);
  }

  applyResponseInterceptor(callback: ResponseInterceptorCallback) {
    if (callback) this.responseInterceptors.push(callback);
  }

  applyAuthorization(authorization: string) {
    this.authorization = authorization;
  }

  client() {
    return {
      get: async (
        url: string,
        config?: FetchRequestConfig,
      ): Promise<RequestHandlerResponse> => buildResponse(
        requestHandler(this.client(), url, undefined, config, 'GET', {
          requestInterceptors: this.requestInterceptors,
          responseInterceptors: this.responseInterceptors,
          preSetAuthorization: this.authorization,
        }),
        'GET',
        config?.headers,
      ),
      post: async (
        url: string,
        body: FetchRequestBody,
        config?: FetchRequestConfig,
      ): Promise<RequestHandlerResponse> => buildResponse(
        requestHandler(
          this.client(),
          url,
          ((body instanceof FormData) ? body : JSON.stringify(body)),
          config,
          'POST',
          {
            requestInterceptors: this.requestInterceptors,
            responseInterceptors: this.responseInterceptors,
            preSetAuthorization: this.authorization,
          },
        ),
        'POST',
        config?.headers,
      ),
      put: async (
        url: string,
        body: FetchRequestBody,
        config?: FetchRequestConfig,
      ): Promise<RequestHandlerResponse> => buildResponse(
        requestHandler(
          this.client(),
          url,
          JSON.stringify(body),
          config,
          'PUT',
          {
            requestInterceptors: this.requestInterceptors,
            responseInterceptors: this.responseInterceptors,
            preSetAuthorization: this.authorization,
          },
        ),
        'PUT',
        config?.headers,
      ),
      patch: async (
        url: string,
        body: FetchRequestBody,
        config?: FetchRequestConfig,
      ): Promise<RequestHandlerResponse> => buildResponse(
        requestHandler(
          this.client(),
          url,
          JSON.stringify(body),
          config,
          'PATCH',
          {
            requestInterceptors: this.requestInterceptors,
            responseInterceptors: this.responseInterceptors,
            preSetAuthorization: this.authorization,
          },
        ),
        'PATCH',
        config?.headers,
      ),
      delete: async (
        url: string,
        config?: FetchRequestConfig,
      ): Promise<RequestHandlerResponse> => buildResponse(
        requestHandler(this.client(), url, undefined, config, 'DELETE', {
          requestInterceptors: this.requestInterceptors,
          responseInterceptors: this.responseInterceptors,
          preSetAuthorization: this.authorization,
        }),
        'DELETE',
        config?.headers,
      ),
      request: async (
        config: FetchRequestConfig,
      ): Promise<RequestHandlerResponse> => buildResponse(
        requestHandler(
          this.client(),
          undefined,
          undefined,
          config,
          undefined,
          {
            requestInterceptors: this.requestInterceptors,
            responseInterceptors: this.responseInterceptors,
            preSetAuthorization: this.authorization,
          },
        ),
        undefined,
      ),
      applyAuthorization: (authorization: string) => {
        this.applyAuthorization(authorization);
      },
    };
  }
}
