import { baseApi2 } from '@/store/queries';
import { personEndpoints } from '@/store/domains/resources/persons';
import type { Information, Military, PersonName, Person, UserAttributesResponse, Avatar } from '@/types/api/persons';

export const personsApi = baseApi2.injectEndpoints({
  endpoints: (build) => ({
    getPersonById: build.query<Person, { personId: string }>(
      { query: ({ personId }) => ({ url: personEndpoints.getPersonByPersonId(personId), method: 'GET' }) },
    ),
    getOfficialName: build.query<PersonName, { personId: string }>(
      {
        query: ({ personId }) => ({ url: personEndpoints.getNamesByPersonId(personId), method: 'GET' }),
        transformResponse: (returnValue: PersonName[]) => returnValue.find((name) => name?.type?.toLowerCase() === 'official'),
      },
    ),
    getAvatar: build.query<Avatar, { personId: string }>(
      {
        query: ({ personId }) => ({ url: personEndpoints.getAvatarByPersonId(personId), method: 'GET' }),
      },
    ),
    getDemographicsByPersonId: build.query<Information, { personId: string }>(
      {
        query: ({ personId }) => ({ url: personEndpoints.getDemographicsByPersonId(personId), method: 'GET' }),
      },
    ),
    getUserAttributesByPersonId: build.query<UserAttributesResponse, { personId: string }>(
      {
        query: ({ personId }) => ({
          url: personEndpoints.getUserAttributesByPersonId(personId), method: 'GET',
        }),
      },
    ),
    getMilitaryStatus: build.query<Military, { personId: string }>(
      {
        query: ({ personId }) => ({ url: personEndpoints.getMilitaryStatus(personId), method: 'GET' }),
      },
    ),
  }),
});

export const {
  useGetPersonByIdQuery,
  useGetOfficialNameQuery,
  useGetAvatarQuery,
  useGetDemographicsByPersonIdQuery,
  useGetUserAttributesByPersonIdQuery,
  useGetMilitaryStatusQuery,
} = personsApi;
