import { getItem, setItem } from '@/helpers/session-storage';
import { MOCK_SCENARIO_KEY } from '@/helpers/session-storage/constants';
import { FetchArgs, createApi, fetchBaseQuery, retry } from '@reduxjs/toolkit/query/react';
import { REHYDRATE } from 'redux-persist';
import { v4 as uuidv4 } from 'uuid';
import {
  PERSISTENCE_DEFAULT_REFETCH_ON_ARG_OR_MOUNT_DURATION,
  PERSISTENCE_DEFAULT_REFETCH_UNUSED_DATA_DURATION,
} from '@/store/helpers/constants';
import { HYDRATE } from 'next-redux-wrapper';
import { errorTrackingHandler, getAuthorizationToken } from '../helpers/request';

type CustomExtraOptions = {
  ssrToken: string;
} | undefined;

const microservicesBase = process?.env?.NEXT_PUBLIC_MICROSERVICES_BASE || '';
const isMocksEnabled = process?.env?.NEXT_PUBLIC_MOCKS_ENABLED || false;

const getCorrelationId = () => {
  const cachedCorrelationId = getItem('X-Correlation-Id');
  if (cachedCorrelationId) { return cachedCorrelationId; }

  const newCorrelationId = uuidv4();
  setItem('X-Correlation-Id', newCorrelationId);
  return newCorrelationId;
};

const customFetchBaseQuery = (
  extraOptions: CustomExtraOptions | undefined,
  etagOverride: string = undefined,
) => fetchBaseQuery({
  baseUrl: '',
  prepareHeaders: (headers) => {
    const token = getAuthorizationToken(extraOptions?.ssrToken);
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    if (etagOverride) {
      headers.set('If-Match', etagOverride);
    }
    headers.set('X-Request-Id', uuidv4());
    headers.set('X-Correlation-Id', getCorrelationId());
    // ! Currently CORS blocked so disabling
    // headers.set('X-FullStory-URL', getFullStoryUrl());

    return headers;
  },
  timeout: 15000,
});

const customBaseQuery = retry(
  async (args: FetchArgs, api, extraOptions: CustomExtraOptions) => {
    const mockScenarioId = getItem(MOCK_SCENARIO_KEY);
    if (isMocksEnabled && mockScenarioId) {
      // At this time, args is not a frozen object
      // eslint-disable-next-line no-param-reassign
      args.url = args?.url?.replace?.(microservicesBase, '/api/mock/api');
    }
    const result = await customFetchBaseQuery(extraOptions)(args, api, extraOptions);
    if (result.error) {
      errorTrackingHandler(result.error.status, result.meta?.request?.headers?.get('x-request-id'), result.meta?.request?.headers?.get('x-correlation-id'));
    }

    if (result.error?.status === 401 || result.meta?.request?.url?.includes('/v1/program/detailprogression')) {
      retry.fail(result.error);
    }

    if (result.error?.status === 409) {
      const newEtag = result.meta?.response?.headers?.get('etag');
      return customFetchBaseQuery(extraOptions, newEtag)(args, api, extraOptions);
    }
    return result;
  },
  { maxRetries: process.env.NODE_ENV === 'test' ? 0 : 3 },
);

export const baseApi2 = createApi({
  reducerPath: 'baseApi2',
  baseQuery: customBaseQuery,
  refetchOnMountOrArgChange: PERSISTENCE_DEFAULT_REFETCH_ON_ARG_OR_MOUNT_DURATION,
  keepUnusedDataFor: PERSISTENCE_DEFAULT_REFETCH_UNUSED_DATA_DURATION,
  extractRehydrationInfo(action, { reducerPath }) {
    return (action?.type === HYDRATE || action?.type === REHYDRATE)
      ? action?.payload?.[reducerPath] : undefined;
  },
  endpoints: () => ({}),
});

/*
https://redux-toolkit.js.org/rtk-query/api/createApi
Typically, you should only have one API slice per base URL that your
application needs to communicate with
*/
export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: undefined, // Currently not used but if needed will require refactoring
  tagTypes: ['Notifications', 'Tasks', 'Vouchers', 'Loa'],
  refetchOnMountOrArgChange: PERSISTENCE_DEFAULT_REFETCH_ON_ARG_OR_MOUNT_DURATION,
  keepUnusedDataFor: PERSISTENCE_DEFAULT_REFETCH_UNUSED_DATA_DURATION,
  serializeQueryArgs: ({ queryArgs, endpointName }) => {
    // Destructure out params from server side to maintain cache
    const { token, baseUrl, ...cleanedArgs } = queryArgs as { token: string, baseUrl: string };
    return `${endpointName}(${JSON.stringify(cleanedArgs)})`;
  },
  extractRehydrationInfo(action, { reducerPath }) {
    return (action?.type === HYDRATE || action?.type === REHYDRATE)
      ? action?.payload?.[reducerPath] : undefined;
  },
  endpoints: () => ({}),
});

type ApiError = { response: { statusText: string, status: number } };
type ExpandedApiError = {
  response: {
    statusText: string,
    status: number,
    data: {
      message: string,
    },
  },
};
export type QueryFnErrorResponse = {
  error: ApiError
  data?: undefined;
};
export const isQueryFnErrorResponse = (response: unknown):
  response is ApiError => (response as ApiError).response !== undefined;

export const isQueryFnExpandedErrorResponse = (response: unknown):
  response is ExpandedApiError => (response as ExpandedApiError).response !== undefined
  && (response as ExpandedApiError).response.data !== undefined;

export default baseApi;
