import { Ref } from 'react';
import { linkDetokenizer } from '@/helpers/link-detokenizer';
import headerLinks from '@/helpers/link-detokenizer/headerLinks';
import IconLink from '@/components/molecules/icon-link';
import CourseSection from '@/components/molecules/course-section';
import LogoutIconLink from '@/components/atoms/logout-icon-link';
import StandardLink from '@/components/atoms/standard-link';
import { useGetCoursesByMembershipsQuery } from '@/store/queries/courses';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import makeAbsoluteUrl from '@/helpers/absolute-url';
import { Course } from '@/types/courseInfoData';
import {
  NavContainer,
  MenuOpenOverlay,
  NavCenterer,
  LinkListsContainer,
  IconLinksContainer,
  IconContainer,
} from './HamburgerMenuDropdown.styles';

interface HamburgerMenuDropdownProps {
  menuOpen: boolean;
  userDefinedAttributes?: string[];
  emailAddress?: string;
  program?: Record<string, any>;
  logoutText?: string;
  forwardRef: Ref<HTMLElement>;
}

function HamburgerMenuDropdown({
  menuOpen,
  userDefinedAttributes = [],
  emailAddress = '',
  program = {},
  logoutText = 'Logout',
  forwardRef,
}: HamburgerMenuDropdownProps) {
  const personId = getPersonIdFromSessionStorage();
  const {
    data: { courses = [] } = {},
  } : {
    data? : { courses?: Course[] }
  } = useGetCoursesByMembershipsQuery({ personId });

  // Link Section
  const defaultAttributes = ['all'];
  const filteredList = linkDetokenizer(
    defaultAttributes.concat(userDefinedAttributes, program?.qualificationLevel, program?.code),
    emailAddress,
    program?.id,
  );

  const regexPatternForCssSelector = /^-\d|^\d|^--|~|!|@|\$|%|\^|&|\*|\(|\)|\+|=|,|\.|\/|'|;|:|"|\?|>|<|\[|\]|\\|{|}|\||`|#|\s/g;
  const headerLinksSection = filteredList.map((list) => (
    <div
      role="group"
      aria-labelledby={`${list.header.replace(regexPatternForCssSelector, '')}Navigation`}
      key={list.header}
    >
      <h2 id={`${list.header.replace(regexPatternForCssSelector, '')}Navigation`}>{list.header}</h2>
      <ul>
        {(list.links).map((item) => (
          <li
            key={`${item.url}-${item.text}`}
          >
            <StandardLink
              href={makeAbsoluteUrl(item.url)}
            >
              {item.text}
            </StandardLink>
          </li>
        ))}
      </ul>
    </div>
  ));

  // Icon Section
  const { iconLinks } = headerLinks;
  const iconLinkSection = iconLinks.map((list, index) => (
    <IconContainer
      key={list.text}
    >
      <IconLink
        id={`hamburger-menu-${list.icon}-${index + 1}`}
        icon={list.icon}
        text={list.text}
        url={makeAbsoluteUrl(list.url)}
        ariaLabel={list.text}
        styleType="brand-brown"
      />
    </IconContainer>
  ));
  return (
    <>
      <NavContainer open={menuOpen} ref={forwardRef}>
        {/*
          TODO: The correct way to go is with inert but FireFox is still implementing it.
          Right now it's in a pre-release state, next version of FireFox should have it implemented.
          https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/inert#browser_compatibility
        */}
        <NavCenterer sx={{ display: menuOpen ? 'block' : 'none' }}>
          <nav
            // have to ignore the type error on inert, as it's not supported yet
            // https://github.com/DefinitelyTyped/DefinitelyTyped/pull/60822
            // @ts-ignore
            inert={menuOpen ? undefined : 'true'}
            aria-label="Main Menu"
            id="MainMenuNavigation"
            data-testid="menu-open"
            role="navigation"
          >
            <CourseSection courseLinks={courses} />
            <LinkListsContainer>
              {headerLinksSection}
            </LinkListsContainer>
            <IconLinksContainer>
              {iconLinkSection}
              <LogoutIconLink logoutText={logoutText} />
            </IconLinksContainer>
          </nav>
        </NavCenterer>
      </NavContainer>
      <MenuOpenOverlay open={menuOpen} />
    </>
  );
}

export default HamburgerMenuDropdown;
