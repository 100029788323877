import { getItem as getItemFromSessionStorage } from '@/helpers/session-storage';
import { MOCK_SCENARIO_KEY } from '@/helpers/session-storage/constants';

import type { FetchClientType } from './fetch-types';

const microservicesBase = process?.env?.NEXT_PUBLIC_MICROSERVICES_BASE || '';
const isMocksEnabled = process?.env?.NEXT_PUBLIC_MOCKS_ENABLED || '';

export default class BaseService {
  client: FetchClientType;

  resourcePath: string;

  baseEndpointUrl: string;

  constructor(client: FetchClientType, resourcePath: string, basePath = microservicesBase) {
    this.client = client;
    this.resourcePath = resourcePath;
    this.baseEndpointUrl = `${basePath}/${resourcePath}`;
  }

  setToken(token: string) {
    if (token) {
      this.client.applyAuthorization(`Bearer ${token}`);
    }
    return this;
  }

  setServerSideBaseUrl() {
    const scenarioId = getItemFromSessionStorage(MOCK_SCENARIO_KEY);
    if (isMocksEnabled && scenarioId) {
      this.baseEndpointUrl = `/api/mock/api/${this.resourcePath}`;
    }
    return this;
  }
}
