import { baseApi2 } from '@/store/queries';
import { learningEndpoints } from '@/store/domains/resources/learning';
import type { Section } from '@/types/api/learning';

export const coursesApi = baseApi2.injectEndpoints({
  endpoints: (build) => ({
    getSectionBySourceId: build.query<Section, { sourceId: string }>(
      {
        query: ({ sourceId }) => ({ url: learningEndpoints.getSectionBySourceId(sourceId), method: 'GET' }),
      },
    ),
    getCoursesBySourceIds: build.query<Section[], { sourceIds: string[] }>({
      async queryFn(
        { sourceIds },
        api,
        extraOptions,
        baseQuery,
      ) {
        if (!sourceIds || sourceIds.length === 0) {
          return { data: [] };
        }
        const coursesDataPromises = sourceIds.map(async (sourceId: string) => {
          const response = await baseQuery({ url: learningEndpoints.getSectionBySourceId(sourceId), method: 'GET' });
          return response.data;
        });

        const coursesData = await Promise.all(coursesDataPromises);

        return { data: coursesData };
      },
    }),
  }),
});

export const {
  useGetSectionBySourceIdQuery,
  useGetCoursesBySourceIdsQuery,
} = coursesApi;
