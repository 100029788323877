import { baseApi2 } from '@/store/queries';
import { studentEndpoints } from '@/store/domains/resources/student';
import { MembershipDetails } from '@/types/api/student/membershipDetails';
import { DateTime } from 'luxon';

import { StudentCourseMembershipResponse, StudentCourseMembership } from '@/types/api/student/studentCourseMembership';
import { PLASubmission } from '@/types/api/student/pla';

const RANGE = {
  min: parseInt(process.env.NEXT_PUBLIC_LEARNING_DEFAULT_RANGE_MINIMUM, 10) || -455,
  max: parseInt(process.env.NEXT_PUBLIC_LEARNING_DEFAULT_RANGE_MAXIMUM, 10) || 180,
};

const rangeMin = DateTime.now().plus({ days: RANGE.min }).toISODate().toString() || '';
const rangeMax = DateTime.now().plus({ days: RANGE.max }).toISODate().toString() || '';

export const studentApi = baseApi2.injectEndpoints({
  endpoints: (build) => ({
    getPrimaryProgramByPersonId: build.query<MembershipDetails, { personId: string }>(
      { query: ({ personId }) => ({ url: studentEndpoints.getPrimaryProgramByPersonId(personId), method: 'GET' }) },
    ),
    getAllProgramsByPersonId: build.query<MembershipDetails[], { personId: string }>(
      { query: ({ personId }) => ({ url: studentEndpoints.getAllProgramsByPersonId(personId), method: 'GET' }) },
    ),
    getMembershipsByPersonIdAndDateRange:
      build.query<StudentCourseMembership[], {
        personId: string,
        min?: string,
        max?: string
      }>(
        { query: ({ personId, min = rangeMin, max = rangeMax }) => ({ url: studentEndpoints.getMembershipsByPersonIdAndDateRange(personId, min, max), method: 'GET' }),
          transformResponse: (response: StudentCourseMembershipResponse) => {
            const filteredMemberships = response?.memberships
              ?.filter(({ startDate, endDate, courseOfferingType, participantId }) => (
                startDate && endDate && courseOfferingType !== 'TT' && participantId
              ));

            const membershipsWithId = filteredMemberships?.map((membership) => ({
              ...membership,
              id: membership.id || `OSIRIS:${membership?.participantId}`,
            }));

            return membershipsWithId;
          } },
      ),
    getStudentLoa: build.query<MembershipDetails, { personId: string }>(
      { query: ({ personId }) => ({ url: studentEndpoints.getLoaByPersonId(personId), method: 'GET' }) },
    ),
    getPLA: build.query<PLASubmission, { irn: string }>(
      { query: ({ irn }) => ({ url: studentEndpoints.getPLA(irn), method: 'GET' }) },
    ),
  }),
});

export const {
  useGetPrimaryProgramByPersonIdQuery,
  useGetAllProgramsByPersonIdQuery,
  useGetMembershipsByPersonIdAndDateRangeQuery,
  useGetStudentLoaQuery,
  useGetPLAQuery,
} = studentApi;
