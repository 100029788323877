import { useEffect } from 'react';
import '@uopx/mui-style/globals.css';
import '@/styles/globals.css';
import Head from 'next/head';
import { builder } from '@builder.io/react';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { SessionProvider } from 'next-auth/react';
import CssBaseline from '@mui/material/CssBaseline';
import { CacheProvider } from '@emotion/react';
import theme from '@/helpers/theme';
import Layout from '@/pages/_layout';
import wrapper from '@/store';
import createEmotionCache from '@/helpers/emotion/createEmotionCache';
import ReactTracker from '@/hoc/react-tracker';
// TODO: Revert back to SSR component once styles are fixed
import AuthRedirect from '@/hoc/AuthRedirectConditionalSsr';
import { getPersonIdFromSessionStorage } from '@/helpers/auth';
import {
  useDevCycleClient,
  withDevCycleProvider,
} from '@devcycle/react-client-sdk';
import { GoogleTagManagerScript } from '@/components/atoms/vendor/google-tag-manager';
import { GoogleDataLayer } from '@/components/atoms/vendor/google-data-layer';
import ErrorBoundary from '@/components/organisms/error-boundary';
import config from '@/helpers/config';

const { Builder } = config;
// ! IMPORTANT for GDPR and CCPA compliance (Pending listener to enable) canTrack has to be false
builder.init(Builder.apiKey, false, null, null, null, Builder.apiVersion);

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

const muiTheme = createTheme(theme);

const EmptyComponent = ({ children }) => children;

function MyApp(props) {
  const dvcClient = useDevCycleClient();
  const personId = getPersonIdFromSessionStorage();
  useEffect(() => {
    if (personId) {
      dvcClient.identifyUser({
        user_id: personId,
        customData: {
          personId,
        },
      });
    }
  }, [personId, dvcClient]);
  const {
    Component, emotionCache = clientSideEmotionCache, pageProps: { session, ...pageProps },
  } = props;
  const { pageTitle } = Component;
  const getLayout = Component.getLayout
    || ((children, title) => <Layout title={title}>{children}</Layout>);
  const AuthWrapper = ((Component.requiresAuthentication ?? true) && !builder.editingMode)
    ? AuthRedirect : EmptyComponent;

  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width" />
      </Head>
      <GoogleTagManagerScript />
      <ThemeProvider theme={muiTheme}>
        <SessionProvider session={session} refetchInterval={15 * 60}>
          <AuthWrapper>
            <GoogleDataLayer personId={session?.user?.personId} />
            <ReactTracker>
              {getLayout(
                <>
                  <CssBaseline />
                  <ErrorBoundary>
                    <Component {...pageProps} />
                  </ErrorBoundary>
                </>,
                pageTitle,
              )}
            </ReactTracker>
          </AuthWrapper>
        </SessionProvider>
      </ThemeProvider>
    </CacheProvider>
  );
}

export default wrapper.withRedux(
  withDevCycleProvider({ sdkKey: process.env.NEXT_PUBLIC_DEVCYCLE_CLIENT_KEY })(MyApp),
);
