export const getTransformedProgramsData = (programs) => {
  let primaryId = '';
  const transformedPrograms = programs.map((program) => {
    const transformedProgram = {
      id: '',
      code: '',
      creditsSummary: {},
      version: '',
      isPrimary: false,
      lastActivityDate: '',
      programCompletionDeadlineDate: '',
      startDate: '',
      programCompletionDate: '',
      programGpa: 0,
      statuses: [],
    };
    const programData = (({ programCodeVersion = [] }) => (
      { programCodeVersion }))(program.externalSystemIds || {});
    if (programData.programCodeVersion.length) {
      const codeVersion = programData.programCodeVersion[0].split(':');
      [transformedProgram.code, transformedProgram.version] = codeVersion;
    }
    transformedProgram.id = program.id || '';
    transformedProgram.isPrimary = program.primary === 'true';
    if (transformedProgram.isPrimary) {
      primaryId = transformedProgram.id;
    }
    transformedProgram.statuses = program.statuses || [];
    transformedProgram.lastActivityDate = program.lastActivityDate || '';
    transformedProgram.programCompletionDeadlineDate = program.programCompletionDeadlineDate || '';
    transformedProgram.startDate = program.startDate || '';
    transformedProgram.programCompletionDate = program.programCompletionDate || '';
    transformedProgram.programGpa = program.programGpa || 0;
    transformedProgram.creditsSummary = {
      assessed: program.creditsAssessed || 0,
      completed: program.creditsCompleted || 0,
      earned: program.creditsEarned || 0,
      remaining: program.creditsRemaining || 0,
      transferred: program.creditsTransferred || 0,
      waived: program.creditsWaived || 0,
    };
    return transformedProgram;
  });
  transformedPrograms.primaryProgramId = primaryId;
  return transformedPrograms;
};

export const getContactList = (programContactsObject) => {
  const enrollmentRepresentativesList = [];
  const academicCounselorsList = [];
  if (programContactsObject
    && programContactsObject.univContacts
    && programContactsObject.univContacts.length) {
    programContactsObject.univContacts.forEach((entry) => {
      academicCounselorsList.push(entry.support
        .filter((rep) => rep.contactGroup.includes('AC') && rep)
        .map((eligibleRep) => ({
          firstName: eligibleRep.person.name.firstName,
          lastName: eligibleRep.person.name.lastName,
          phoneNumber: eligibleRep.person.phones[0].phoneNumber,
          email: eligibleRep.person.email[0].emailAddress,
          location: entry.academicProgram.offeredAtLocation.name,
        })));
      enrollmentRepresentativesList.push(entry.support
        .filter((rep) => rep.contactGroup.includes('EC') && rep)
        .map((eligibleRep) => ({
          firstName: eligibleRep.person.name.firstName,
          lastName: eligibleRep.person.name.lastName,
          phoneNumber: eligibleRep.person.phones[0].phoneNumber,
          email: eligibleRep.person.email[0].emailAddress,
          location: entry.academicProgram.offeredAtLocation.name,
          programCode: entry.academicProgram.academicProgramCode,
        })));
    });
  }

  return {
    academicCounselor: academicCounselorsList[0],
    enrollmentRep: enrollmentRepresentativesList[0],
    contacts: academicCounselorsList[0] ?? enrollmentRepresentativesList[0] ?? [],
  };
};
