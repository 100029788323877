import configs from '@/helpers/config';
import { navigate } from '@/helpers/window-functions';

const { blackboard } = configs;

const getBBRootUrl = () => blackboard.baseUrl;

const getBBClassUrl = (sectionId) => `${blackboard.baseUrl}/?new_loc=/webapps/apol-integration-tools-${blackboard.routerId}/router%3Flocation%3DCOURSE%26locationId%3D${sectionId.startsWith('OSIRIS') ? 'OSIRIS' : 'SDW'}%3A${sectionId.startsWith('OSIRIS') ? sectionId.substring(7) : sectionId.substring(4)}`;

const openBlackboard = (sectionId) => {
  navigate(sectionId ? getBBClassUrl(sectionId) : getBBRootUrl());
};

export {
  getBBRootUrl,
  getBBClassUrl,
  openBlackboard,
};
